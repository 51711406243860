<template>
	<a rel="noopener noreferrer" @click.prevent="openBookingEngine({ ...$props })">
		<slot />
	</a>
</template>

<script setup>
import BookingEngine from '@becurious/cubilis';

const { locale } = useI18n();
const bookingEngine = useState('bookingEngine', () => {});

defineProps({
	language: { type: String, default: '' },
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	pax: { type: String, default: '' },
	packageid: { type: String, default: '' },
});

const openBookingEngine = (options) => {
	if (!options.language) {
		options = { ...options, language: locale.value };
	}

	window.open(bookingEngine.value.getUrl(options));
};

onMounted(() => {
	bookingEngine.value = new BookingEngine({
		hotelID: 'leut-koffiebar-en-logies-vlieland/',
	});
});
</script>

<style lang="scss" scoped>
a {
	color: #fff;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
</style>
